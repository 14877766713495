import React from "react";
import es from "services/localization";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Galery from "pages/Galery";

export default function Home() {
  const cloudinary = window.cloudinary;
  const myCropWidget = cloudinary.createUploadWidget(
    {
      cloudName: "albaysanti",
      uploadPreset: "zjfqln8d",
      folder: "pruebas",
      cropping: false,
      sources: ["local"],
      language: "es",
      text: es,
      resourceType: "image",
      context: { autor: "santiggd@gmail.com" },
      singleUploadAutoClose: false,
    },
    (error, result) => {
      console.log("error result", error, result);
    }
  );
  return (
    <Box sx={{ padding: 2, textAlign: "center" }}>
      <h1 onClick={() => myCropWidget.open()}>Fotos</h1>
      <Button onClick={() => myCropWidget.open()} variant={"contained"}>
        Subir foto
      </Button>
      <Galery />
    </Box>
  );
}
