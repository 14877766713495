import React from "react";

const Authenticated = ({ component }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  React.useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      window.location.href = "/login";
    }
    setIsAuthenticated(isAuthenticated);
  }, []);

  return isAuthenticated ? component : null;
};

export default Authenticated;
