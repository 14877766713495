import React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import PrintIcon from "@mui/icons-material/Print";
import cld from "services/CloudinaryService";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const PrintButton = ({ imgObj }) => {
  const [open, setOpen] = React.useState(false);
  const [copies, setCopies] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          left: 0,
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Fab
          variant="extended"
          onClick={() => {
            handleClickOpen();
          }}
        >
          <PrintIcon sx={{ mr: 1 }} /> Imprimir
        </Fab>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Imprimir</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Cuantas copias quieres imprimir?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="copias"
              label="Copias"
              type="number"
              variant="standard"
              value={copies}
              onChange={(e) => {
                setCopies(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                setLoading(true);
                const pdfUrl = cld.getPdfUrl(imgObj, {});
                fetch("https://api.printnode.com/printjobs", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization:
                      "Basic " +
                      btoa(`${process.env.REACT_APP_PRINTNODE_API_KEY}:`),
                  },
                  body: JSON.stringify({
                    printerId: 71654111,
                    contentType: "pdf_uri",
                    content: pdfUrl,
                    options: {
                      fit_to_page: true,
                      copies: copies,
                      paper: `10x15cm 4"x6"`,
                    },
                  }),
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((data) => {
                    console.log(data);
                    handleClose();
                    setCopies(1);
                    setLoading(false);
                  });
              }}
              disabled={loading}
            >
              Imprimir
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
        <Collapse in={showAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Se han enviado a imprimir {copies} {copies > 1 ? "copias" : "copia"}
          </Alert>
        </Collapse>
      </Box>
    </React.Fragment>
  );
};

export default PrintButton;
