import React from "react";
// import UploadsService from "services/UploadsService";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";

import es from "services/localization";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import cld from "services/CloudinaryService";
import * as FirestoreService from "services/firestore";

import PrintButton from "./components/PrintButton";

import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import { Virtual } from "swiper";
import { Controller } from "swiper";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/navigation";

export default function Galery() {
  const [loading, setLoading] = React.useState(true);
  const [uploads, setUploads] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [swiperRef, setSwiperRef] = React.useState(null);
  const [cloudinaryWidget, setCloudinaryWidget] = React.useState(null);
  const [printEnabled, setPrintEnabled] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = FirestoreService.streamUploads(
      (querySnapshot) => {
        const updatedUploads = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        console.log("updatedUploads", updatedUploads);
        // for (let i = 0; i < 8; i++) {
        //   updatedUploads.push(...updatedUploads);
        // }
        setUploads(updatedUploads);

        setLoading(false);
      },
      (error) => console.error("grocery-list-item-get-fail", error)
    );
    return unsubscribe;
  }, []);

  React.useEffect(() => {
    setCloudinaryWidget(
      window.cloudinary.createUploadWidget(
        {
          cloudName: "albaysanti",
          uploadPreset: "zjfqln8d",
          folder: "pruebas",
          cropping: false,
          sources: ["local"],
          language: "es",
          text: es,
          resourceType: "image",
          context: { autor: "santiggd@gmail.com" },
          singleUploadAutoClose: false,
        },
        (error, result) => {
          console.log("error result", error, result);
          if (result.event === "success") {
            console.log("result", result);
            FirestoreService.addUpload(result.info);
          }
        }
      )
    );
  }, []);

  React.useEffect(() => {
    const unsubscribe = FirestoreService.streamPrinters(
      (querySnapshot) => {
        const updatedPrinters = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        console.log("updatedPrinters", updatedPrinters);
        if (updatedPrinters.length > 0 && updatedPrinters[0].enabled) {
          setPrintEnabled(true);
        } else {
          setPrintEnabled(false);
        }
        // setPrintEnabled(updatedPrinters);
      },
      (error) => console.error("grocery-list-item-get-fail", error)
    );
    return unsubscribe;
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  console.log("uploads", uploads);

  return (
    <div>
      {/* <h1>Galería</h1> */}
      <ImageList sx={{ width: "100%" }} variant="masonry" cols={2} gap={8}>
        {uploads.map((item, index) => {
          const imgUrl = cld.getImgUrl(item);
          return (
            <ImageListItem key={`${item.asset_id}_${index}`}>
              <img
                src={`${imgUrl}`}
                // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.original_filename}
                loading="lazy"
                onClick={() => {
                  setOpen(index + 1);
                }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
      {cloudinaryWidget && (
        <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          color="primary"
          aria-label="add"
        >
          <AddIcon onClick={() => cloudinaryWidget.open()} />
        </Fab>
      )}
      <Dialog fullScreen open={Boolean(open)} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar
            variant="dense"
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <IconButton
              // edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ position: "relative" }}>
          <Swiper
            onSwiper={setSwiperRef}
            modules={[Virtual, Controller, Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            virtual
            navigation={true}
            initialSlide={open - 1}
            // loop={true}
          >
            {uploads.map((item, index) => {
              const imgUrl = cld.getImgUrl(item, {});
              return (
                <SwiperSlide key={item.asset_id} virtualIndex={index}>
                  <Box
                    // component="img"
                    sx={{
                      width: "100vw",
                      height: "calc(100vh - 48px)",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: `url(${imgUrl})`,
                      position: "relative",
                    }}
                    // src={`${item.secure_url}`}
                    // // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    // alt={item.original_filename}
                    // loading="lazy"
                  ></Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {printEnabled && swiperRef && (
            <PrintButton imgObj={uploads[swiperRef.realIndex]} />
          )}
        </Box>
      </Dialog>
    </div>
  );
}
