import { Cloudinary } from "@cloudinary/url-gen";
import { Transformation } from "@cloudinary/url-gen";
import {
  scale,
  limitFill,
  fill,
  crop,
} from "@cloudinary/url-gen/actions/resize";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { byAngle } from "@cloudinary/url-gen/actions/rotate";
import { vignette } from "@cloudinary/url-gen/actions/effect";
// import {byRadius, max} from "@cloudinary/url-gen/actions/roundCorners";
import { saturation, hue } from "@cloudinary/url-gen/actions/adjust";
import { Position } from "@cloudinary/url-gen/qualifiers/position";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
import { image, text } from "@cloudinary/url-gen/qualifiers/source";
import { TextStyle } from "@cloudinary/url-gen/qualifiers/textStyle";
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn";

/**
 * const imgObj = {
  _id: "630fabac84e9479411af05ea",
  notification_type: "upload",
  timestamp: "2022-08-31T18:42:24+00:00",
  request_id: "463bd7091735c51d7bead0ac5bb47516",
  asset_id: "12adaa7a303eb653a9a4e1bc48b3d224",
  public_id: "pruebas/ouqsagyn23a0o6luszul",
  version: 1661971343,
  version_id: "c43282b5f468268f069000722da21f15",
  width: 1280,
  height: 958,
  format: "jpg",
  resource_type: "image",
  created_at: "2022-08-31T18:42:23Z",
  tags: [],
  bytes: 150153,
  type: "upload",
  etag: "f518f5fca1cc60605cc2f0e47b1b3b97",
  placeholder: false,
  url: "http://res.cloudinary.com/albaysanti/image/upload/v1661971343/pruebas/ouqsagyn23a0o6luszul.jpg",
  secure_url:
    "https://res.cloudinary.com/albaysanti/image/upload/v1661971343/pruebas/ouqsagyn23a0o6luszul.jpg",
  folder: "pruebas",
  context: {
    custom: {
      autor: "santiggd@gmail.com",
    },
  },
  original_filename: "IMG-20190823-WA0003",
};

4623x3460
 */

const cloudinary = new Cloudinary({
  cloud: {
    cloudName: "albaysanti",
  },
});

const getImg = (imgObj, options) => {
  const img = cloudinary.image(imgObj.public_id);

  const isLandscape = imgObj.width > imgObj.height;

  // const width = imgObj.width;
  // const height = imgObj.height;
  const width = isLandscape ? 1500 : 1000;
  const height = isLandscape ? 1000 : 1500;

  const overlayWidth = isLandscape
    ? Math.round(width * 0.173047)
    : Math.round(height * 0.173047);
  const overlayXOffset = isLandscape
    ? Math.round(width * 0.025978)
    : Math.round(height * 0.030978);
  const overlayYOffset = isLandscape
    ? Math.round(width * 0.010815)
    : Math.round(height * 0.028915);

  const aspectRatio = isLandscape ? "15:10" : "10:15";
  // const width = isLandscape ? 1500 : 1000;

  img
    .resize(
      fill()
        .width(width)
        .aspectRatio(aspectRatio)
        .gravity(focusOn(FocusOn.faces()))
    )
    .overlay(
      source(
        image(
          "pruebas/Sello_negro_fondo_degradado_ovalo_2_wq6mke"
        ).transformation(
          new Transformation().resize(scale().width(overlayWidth))
        )
      ).position(
        new Position()
          .gravity(compass("south_east"))
          .offsetX(overlayXOffset)
          .offsetY(overlayYOffset)
      )
    );

  return img;
};

const cloudinaryService = {
  getImgUrl: (imgObj, options) => {
    return getImg(imgObj, options).toURL();
  },
  getPdfUrl: (imgObj, options) => {
    return getImg(imgObj, options).rotate(byAngle(90)).format("pdf").toURL();
  },
};

export default cloudinaryService;
