import React from "react";
import Box from "@mui/material/Box";

import cld from "services/CloudinaryService";
import * as FirestoreService from "services/firestore";

import { Virtual } from "swiper";
import { Controller } from "swiper";
import { Navigation } from "swiper";
import { Autoplay } from "swiper";
import { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/navigation";

export default function Galery() {
  const [loading, setLoading] = React.useState(true);
  const [uploads, setUploads] = React.useState([]);
  const [swiperRef, setSwiperRef] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = FirestoreService.streamUploads(
      (querySnapshot) => {
        const updatedUploads = querySnapshot.docs.map((docSnapshot) =>
          docSnapshot.data()
        );
        console.log("updatedUploads", updatedUploads);
        // for (let i = 0; i < 8; i++) {
        //   updatedUploads.push(...updatedUploads);
        // }
        setUploads(updatedUploads);

        setLoading(false);
      },
      (error) => console.error("grocery-list-item-get-fail", error)
    );
    return unsubscribe;
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Swiper
        onSwiper={setSwiperRef}
        modules={[Virtual, Controller, Navigation, Autoplay, EffectFade]}
        spaceBetween={50}
        slidesPerView={1}
        // virtual={{
        //   addSlidesAfter: 2,
        //   addSlidesBefore: 0,
        //   enabled: true,
        // }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
          pauseOnMouseEnter: false,
          waitForTransition: false,
        }}
        speed={2000}
        loop={true}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        // navigation={true}
        // initialSlide={1}
        // loop={true}
      >
        {uploads.map((item, index) => {
          const imgUrl = cld.getImgUrl(item, {});
          return (
            <SwiperSlide key={item.asset_id} virtualIndex={index}>
              <Box
                // component="img"
                sx={{
                  width: "100vw",
                  height: "100vh",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage: `url(${imgUrl})`,
                  position: "relative",
                  backgroundColor: "black",
                }}
                // src={`${item.secure_url}`}
                // // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                // alt={item.original_filename}
                // loading="lazy"
              ></Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
