const es = {
  es: {
    or: "O",
    back: "Atrás",
    advanced: "Avanzado",
    close: "Cerrar",
    no_results: "No se encontraron resultados",
    search_placeholder: "Buscar",
    about_uw: "Sobre uw",
    search: {
      placeholder: "Buscar...",
      reset: "Borrar búsqueda",
    },
    menu: {
      files: "Archivos",
      web: "Web Address",
      camera: "Camera",
      gsearch: "Image Search",
      gdrive: "Google Drive",
      dropbox: "Dropbox",
      facebook: "Facebook",
      instagram: "Instagram",
      shutterstock: "Shutterstock",
      getty: "gettyimages",
      istock: "iStock",
      unsplash: "Unsplash",
    },
    selection_counter: {
      selected: "Seleccionado",
    },
    actions: {
      upload: "Subir",
      next: "Siguiente",
      clear_all: "Limpiar todo",
      log_out: "Cerrar sesión",
    },
    notifications: {
      general_error: "Ocurrió un error",
      general_prompt: "¿Estás seguro?",
      limit_reached: "No se pueden seleccionar más archivos",
      invalid_add_url: "La URL no es válida",
      invalid_public_id: "El ID público no puede contener \\,?,&,#,%,<,>.",
      no_new_files: "The files have already been uploaded.",
      image_purchased: "Image Purchased",
      video_purchased: "Video Purchased",
      purchase_failed: "Purchase failed. Please try again.",
      service_logged_out: "Service logged out due to error",
      great: "Great",
      image_acquired: "Image Acquired",
      video_acquired: "Video Acquired",
      acquisition_failed: "Acquisition failed. Please try again.",
    },
    advanced_options: {
      public_id_ph: "Public ID",
      tags_ph: "Add a tag",
      add_new: "Add a new tag:",
      upload_preset_placeholder: "Upload Preset",
    },
    landscape_overlay: {
      title: "Landscape mode isn't supported",
      description: "Rotate your device back to portrait mode to continue.",
    },
    aria_label: {
      close: "Cerrar",
      back: "Atrás",
    },
    queue: {
      title: "Cola de subidas",
      title_uploading_with_counter: "Subiendo {{num}} elementos",
      title_uploading: "Subiendo elementos",
      mini_title: "Subidas",
      mini_title_uploading: "Subiendo",
      show_completed: "Mostrar completados",
      retry_failed: "Reintentar fallidos",
      abort_all: "Cancelar todo",
      upload_more: "Subir más",
      done: "Hecho",
      mini_upload_count: "{{num}} subidas",
      mini_failed: "{{num}} fallaron",
      statuses: {
        uploading: "Subiendo...",
        error: "Error",
        uploaded: "Hecho",
        aborted: "Cancelado",
      },
    },
    uploader: {
      filesize: {
        na: "N/A",
        b: "{{size}} Bytes",
        k: "{{size}} KB",
        m: "{{size}} MB",
        g: "{{size}} GB",
        t: "{{size}} TB",
      },
      errors: {
        file_too_large:
          "El tamaño del fichero ({{size}}) excede el máximo permitido de ({{allowed}})",
        max_dimensions_validation:
          "Las dimensiones de la imagen ({{width}}X{{height}}) son superiores a las máximas permitidas: ({{maxWidth}}X{{maxHeight}})",
        min_dimensions_validation:
          "Las dimensiones de la imagen ({{width}}X{{height}}) son inferiores a las minimas permitidas: ({{minWidth}}X{{minHeight}})",
        unavailable: "No disponible",
        max_number_of_files: "Máximo número de ficheros superado",
        allowed_formats: "El formato del fichero no es permitido",
        max_file_size: "El fichero es demasiado grande",
        min_file_size: "El fichero es demasiado pequeño",
      },
      close_mid_upload: "Hay subidas en curso. Pulse OK para cancelarlas.",
    },
    crop: {
      title: "Crop",
      crop_btn: "Crop",
      skip_btn: "Skip",
      reset_btn: "Reset",
      close_btn: "Yes",
      close_prompt: "Closing will cancel all uploads, Are you sure?",
      image_error: "Error loading image",
      corner_tooltip: "Drag corner to resize",
      handle_tooltip: "Drag handle to resize",
    },
    camera: {
      capture: "Capture",
      cancel: "Cancel",
      take_pic: "Take a picture and upload it",
      explanation:
        "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.",
      camera_error: "Failed to access camera",
      retry: "Retry Camera",
      file_name: "Camera_{{time}}",
    },
    dropbox: {
      no_auth_title: "Upload files from your Dropbox account.",
      no_auth_action: "Connect to Dropbox",
      no_photos: "No Photos",
      no_files: "No Files",
      root_crumb: "Root",
      list_headers: {
        select: "Select",
        name: "Name",
        modified: "Modified",
      },
      menu: {
        browse: "Subir",
        recent: "Recientes",
      },
      authenticating: "Authenticating...",
    },
    facebook: {
      no_photos: "No photos...",
      no_auth_title: "Upload files from your Facebook account.",
      no_auth_action: "Connect to Facebook",
      no_auth_statement: "We will not post anything without your permission.",
      album_subtitle: "{{count}} photos",
      menu: {
        uploaded: "Your Photos",
        tagged: "Photos of You",
        albums: "Albums",
      },
    },
    google_drive: {
      no_auth_title: "Upload files from your Google Drive.",
      no_auth_action: "Connect to Google Drive",
      search: {
        placeholder: "Search...",
        reset: "Reset search",
      },
      grid: {
        folders: "Folders",
        files: "Files",
        empty_folder: "This folder is empty.",
      },
      recent: "Recent",
      starred: "Starred",
      my_drive: "My Drive",
      shared_drive: "Shared drives",
      search_results: "Search results",
      shared_with_me: "Shared with me",
      name: "Name",
      modifiedTime: "Last modified",
      modifiedByMeTime: "Last modified by me",
      viewedByMeTime: "Last opened by me",
      notifications: {
        retrieve_failed: "Failed to retrieve upload data from Google Drive.",
      },
    },
    image_search: {
      main_title: "Image Search",
      inputPlaceholder: "Search for images",
      customPlaceholder: "Search {{site}}",
      show_options: "Show Options",
      hide_options: "Hide Options",
      filters_title: "Site",
      all: "all",
      rights: "Usage rights:",
      rights_options: {
        not_filtered: "not filtered by licence",
        free: "free to use or share",
        free_com: "free to use or share, even commercially",
        free_mod: "free to use share or modify",
        free_mod_com: "free to use, share or modify, even commercially",
      },
      search_error: "Search failed, please try again.",
    },
    instagram: {
      no_auth_title: "Upload photos from your Instagram account.",
      no_auth_action: "Connect to Instagram",
      header_title: "Your Recent Instagram Photos",
      authenticating: "Authenticating...",
    },
    local: {
      browse: "Subir",
      dd_title_single: "Arrastra aquí",
      dd_title_multi: "Arrastra aquí",
      drop_title_single: "Arrastra aquí",
      drop_title_multiple: "Arrastra aquí",
    },
    shutterstock: {
      no_auth_title: "Upload assets from your Shutterstock account.",
      toggle_filters_button: "Filters",
      no_auth_action: "Connect to Shutterstock",
      authenticating: "Authenticating...",
      statement:
        "Shutterstock offers the best quality, royalty free stock images, photos, vectors, illustrations, videos and music for nearly any application.",
      reg_link_text: "Click here to create a Shutterstock account",
      next_btn: "Next",
      media_types: {
        images: "Images",
        videos: "Videos",
      },
      filters: {
        more_label: "More",
        sort_options: {
          label: "Sort by",
          newest: "New",
          relevance: "Relevant",
          popular: "Popular",
          random: "Random",
        },
        people: {
          label: "People",
          only_images_with_people: "Only images with people",
          only_videos_with_people: "Only videos with people",
        },
        gender: {
          label: "Gender",
          male: "Male",
          female: "Female",
          both: "Both",
        },
        size: {
          label: "Size",
          small: "Small",
          medium: "Medium",
          large: "Large",
        },
        clear: "clear",
        orientation: {
          label: "Orientation",
          horizontal: "Horizontal",
          vertical: "Vertical",
        },
        color: "Color",
        colors: {
          red: "red",
          orange: "orange",
          amber: "amber",
          yellow: "yellow",
          lime: "lime",
          green: "green",
          teal: "teal",
          turquoise: "turquoise",
          aqua: "aqua",
          azure: "azure",
          blue: "blue",
          purple: "purple",
          orchid: "orchid",
          magenta: "magenta",
        },
        safe: "Safe",
        all_categories: "All Categories",
        types: {
          all: "All image types",
          illustration: "Illustration",
          photo: "Photo",
          vector: "Vector",
        },
        duration: {
          label: "Duration",
          short: "Short",
          short_tip: "< 4 minutes",
          long: "Long",
          long_tip: "> 20 minutes",
        },
        resolution: {
          label: "Resolution",
          standard_definition: "SD",
          standard_definition_tip: "Standard Definition",
          high_definition: "HD",
          high_definition_tip: "High Definition",
          "4k": "4k",
          "4k_tip": "4k",
        },
      },
      filter_summary: {
        label: "Filters: {{- summary}}",
        gender: "only {{value}}",
        people: "with {{value}} people",
        color: "color: {{value}}",
        category: "in {{- value}}",
        unsafe: "unsafe",
      },
      purchase_page: {
        purchase_btn_label: "Purchase",
        plans: {
          label: "Select Plan",
          years: "years",
          days: "days",
          expired: "expired",
          exceeded: "exceeded",
          manage_plans: "Manage your Shutterstock plans...",
          no_plan: "Your current plan does not include ",
        },
        format_and_size: {
          label: "Select Format & Size",
        },
        aspect_ratio: "Aspect Ratio",
        author: "Author",
        description: "Description",
        button_label: {
          purchase: "Purchase",
          acquire: "Purchase",
          next: "Next",
          upload: "Upload",
          purchasing: "Purchasing...",
          downloading: "Downloading...",
        },
        button_description: {
          next: "You already own this {{assetType}} Click Next to continue",
          upload: "You already own this {{assetType}} Click Upload to continue",
          purchasing: "Purchasing - This may take a few seconds...",
          downloading: "Downloading - This may take a few seconds...",
        },
        duration: "Duration",
        fps: "FPS",
        sizes: {
          vector_eps: "Vector",
          small_jpg: "Small",
          medium_jpg: "Medium",
          huge_jpg: "Huge",
          web: "Web",
          sd: "SD",
          hd: "HD",
          "4k": "4K",
        },
        file_limit_exceeded:
          "Some options have exceeded the upload file-size limit.",
        time_left: "{{time}} left",
      },
      purchase_preview: {
        loading_preview: "Loading preview",
        open_in_new_window: "Open in new window",
      },
      dictionary: {
        image: "image",
        video: "video",
      },
      home_page: {
        results_header: "Popular",
        categories_header: "Categories",
      },
      search: {
        placeholder: "Search...",
        reset: "Reset search",
      },
    },
    getty: {
      no_auth_title: "Upload assets from your Getty Images account.",
      no_auth_action: "Connect to Getty Images",
      no_auth_statement:
        "Find the perfect royalty-free image for your next project from the world's best photo library of creative stock photos, vector art illustrations and stock.",
      reg_link_text: "Click here to create a Getty Images account",
      select: "Select",
      search: {
        placeholder: "Search...",
        reset: "Reset search",
      },
      media_types: {
        images: "Images",
        videos: "Videos",
      },
      media_types_singular: {
        images: "image",
        videos: "video",
      },
      popular_categories: "Popular categories",
      diversity_categories: "Diversity categories",
      filters: {
        toggle_filters_button: "Filters",
        clear: "Clear",
        available_for_creative_images:
          "Filter is available only for creative images",
        available_for_creative_videos:
          "Filter is available only for creative videos",
        available_for_creative_editorial_videos:
          "Filter is available only for creative or editorial images",
        sort_options: {
          label: "Sort by",
          newest: "Newest",
          best_match: "Best match",
          most_popular: "Most popular",
          random: "Random",
        },
        orientation: {
          label: "Orientation",
          horizontal: "Horizontal",
          vertical: "Vertical",
          square: "Square",
          panoramic_horizontal: "Panoramic horizontal",
          panoramic_vertical: "Panoramic vertical",
        },
        number_of_people: {
          label: "Number of People",
          none: "None",
          one: "One",
          two: "Two",
          group: "Group",
        },
        license: {
          label: "License Type",
          creative: "Creative",
          editorial: "Editorial",
          all: "All",
        },
        license_model: {
          label: "License model",
          rightsready: "Rights-ready",
          royaltyfree: "Royality-free",
          all: "RF and RR",
        },
        colors: {
          label: "Colors",
        },
        age_of_people: {
          label: "Age",
          baby: "Baby",
          child: "Child",
          teenager: "Teenager",
          young_adult: "Young adult",
          adult: "Adult",
          adults_only: "Adults only",
          mature_adult: "Mature adult",
          senior_adult: "Senior adult",
        },
        ethnicity: {
          label: "Ethnicity",
          black: "Black",
          caucasian: "Caucasian",
          east_asian: "East Asian",
          hispanic_latino: "Hispanic/LatinX",
          middle_eastern: "Middle Eastern",
          mixed_race_person: "Mixed Race Person",
          multiethnic_group: "Multi-Ethnic gGroup",
          native_american_first_nations: "Native American/First Nations",
          pacific_islander: "Pacific Islander",
          south_asian: "South Asian",
          southeast_asian: "Southeast Asian",
        },
        resolution: {
          label: "Resolution",
        },
        duration: {
          label: "Duration",
        },
        more: {
          label: "More",
          safe: "Safe (Exclude nudity)",
          exclude_editorial_use_only: "Exclude 'Editorial use only'",
          embed_content_only: "Embeddable images",
        },
        minimumSize: {
          label: "Image resolution",
          large: "12 MP and larger",
          x_large: "16 MP and larger",
          xx_large: "21 MP and larger",
        },
        compositions: {
          label: "Image style",
          abstract: "Abstract",
          portrait: "Portrait",
          close_up: "Close-up",
          sparse: "Sparse",
          cut_out: "Cut out",
          full_frame: "Full frame",
          copy_space: "Copy space",
          macro: "Macro",
          still_life: "Still life",
        },
        videoCompositions: {
          label: "Composition",
          close_up: "Close-up",
          candid: "Candid",
          looking_at_camera: "Looking at camera",
        },
        frameRates: {
          label: "Frame Rate",
        },
        viewpoint: {
          label: "Viewpoint",
          lockdown: "Lockdown",
          panning: "Panning",
          tracking_shot: "Tracking shot",
          aerial_view: "Aerial view",
          high_angle_view: "High angle view",
          low_angle_view: "Low angle view",
          tilt: "Tilt",
          point_of_view: "Point of view",
        },
        imageTechniques: {
          label: "Image Technique",
          realtime: "Realtime",
          time_lapse: "Time lapse",
          slow_motion: "Slow motion",
          color: "Color",
          black_and_white: "Black and white",
          animation: "Animation",
          selective_focus: "Selective focus",
        },
        summary: {
          label: "Filters",
          color: "color: {{color}}",
          number_of_people: {
            none: "Without people",
            one: "With one person",
            two: "With two people",
            group: "With group people",
          },
          license_type: {
            all: "All types",
            creative: "Creative",
            editorial: "Editorial",
          },
          orientations: {
            square: "Square",
            vertical: "Vertical",
            horizontal: "Horizontal",
            panoramic_horizontal: "Panoramic horizontal",
            panoramic_vertical: "Panoramic vertical",
          },
          minimum_size: {
            large: "12 MP and larger",
            x_large: "16 MP and larger",
            xx_large: "21 MP and larger",
          },
          compositions: {
            abstract: "Abstract",
            portrait: "Portrait",
            close_up: "Close-up",
            sparse: "Sparse",
            cut_out: "Cut out",
            full_frame: "Full frame",
            copy_space: "Copy space",
            macro: "Macro",
            still_life: "Still life",
          },
          image_techniques: {
            realtime: "Realtime",
            time_lapse: "Time lapse",
            slow_motion: "Slow motion",
            color: "Color",
            black_and_white: "Black and white",
            animation: "Animation",
            selective_focus: "Selective focus",
          },
          viewpoints: {
            lockdown: "Lockdown",
            panning: "Panning",
            tracking_shot: "Tracking shot",
            aerial_view: "Aerial view",
            high_angle_view: "High angle view",
            low_angle_view: "Low angle view",
            tilt: "Tilt",
            point_of_view: "Point of view",
          },
          video_compositions: {
            close_up: "Close-up",
            candid: "Candid",
            looking_at_camera: "Looking at camera",
          },
          license_models: {
            rightsready: "Rights-ready",
            royaltyfree: "Royality-free",
            all: "RF and RR",
          },
          exclude_nudity: "Safe (Exclude nudity)",
          exclude_editorial_use_only: "Exclude 'Editorial use only'",
          embed_content_only: "Embeddable images",
        },
      },
      purchase: {
        select_plan: "Select Plan",
        select_format: "Select Format & Size",
        manage_plans: "Manage Plans",
        format: "Select Format & Size",
        credit: "Credit",
        credits: "Credits",
        plan_exceeded: "Plan Exceeded",
        size: {
          x_small: "X Small",
          small: "Small",
          medium: "Medium",
          large: "Large",
          x_large: "X Large",
          xx_large: "XX Large",
          vector: "Vector",
        },
        credits_remaining: "credits remainings",
        media_type_not_included:
          "Your current plan does not include this {{mediaType}}",
        images: "images",
        videos: "videos",
        days_left: "days left",
        credits_balance_too_small: "Your credits balance is too small",
        collection: "Collection",
        item_id: "Creative #",
        license_type: "License type",
        release_info: "Release info",
        location: "Location",
        description: "Description",
        rightsmanaged: "Rights-Managed/Rights-Ready",
        royaltyfree: "Royalty-Free",
        cost: "Cost",
      },
      buttons: {
        next: "Next",
        upload: "Upload",
        purchase: "Acquire",
      },
    },
    istock: {
      no_auth_title: "Upload files from your iStock by Getty Images account.",
      no_auth_action: "Connect to iStock",
      no_auth_statement:
        "iStock by Getty Images is one of the world's leading stock content marketplaces, offering millions of hand-picked premium images at ridiculously low prices that you can only get from us.",
      reg_link_text: "Click here to create an iStock by Getty Images account",
      select: "Select",
      search: {
        placeholder: "Search...",
        reset: "Reset search",
      },
      media_types: {
        images: "Images",
        videos: "Videos",
      },
      media_types_singular: {
        images: "image",
        videos: "video",
      },
      popular_categories: "Popular categories",
      diversity_categories: "Diversity categories",
      filters: {
        toggle_filters_button: "Filters",
        clear: "Clear",
        sort_options: {
          label: "Sort by",
          newest: "Newest",
          best_match: "Best match",
          most_popular: "Most popular",
          random: "Random",
        },
        orientation: {
          label: "Orientation",
          horizontal: "Horizontal",
          vertical: "Vertical",
          square: "Square",
          panoramic_horizontal: "Panoramic horizontal",
          panoramic_vertical: "Panoramic vertical",
        },
        number_of_people: {
          label: "Number of People",
          none: "None",
          one: "One",
          two: "Two",
          group: "Group",
        },
        license: {
          label: "License Type",
          all: "All",
          creative: "Creative",
          editorial: "Editorial",
        },
        colors: {
          label: "Colors",
        },
        age_of_people: {
          label: "Age",
          baby: "Baby",
          child: "Child",
          teenager: "Teenager",
          young_adult: "Young adult",
          adult: "Adult",
          adults_only: "Adults only",
          mature_adult: "Mature adult",
          senior_adult: "Senior adult",
        },
        ethnicity: {
          label: "Ethnicity",
          black: "Black",
          caucasian: "Caucasian",
          east_asian: "East Asian",
          hispanic_latino: "Hispanic/LatinX",
          middle_eastern: "Middle Eastern",
          mixed_race_person: "Mixed Race Person",
          multiethnic_group: "Multi-Ethnic gGroup",
          native_american_first_nations: "Native American/First Nations",
          pacific_islander: "Pacific Islander",
          south_asian: "South Asian",
          southeast_asian: "Southeast Asian",
        },
        resolution: {
          label: "Resolution",
        },
        duration: {
          label: "Duration",
        },
        more: {
          label: "More",
          safe: "Safe (Exclude nudity)",
        },
        summary: {
          label: "Filters",
          color: "color: {{color}}",
          license_type: {
            all: "All types",
            creative: "Creative",
            editorial: "Editorial",
          },
          number_of_people: {
            none: "Without people",
            one: "With one person",
            two: "With two people",
            group: "With group people",
          },
          orientations: {
            horizontal: "Horizontal",
            vertical: "Vertical",
            square: "Square",
            panoramic_horizontal: "Panoramic horizontal",
            panoramic_vertical: "Panoramic vertical",
          },
          age_of_people: {
            baby: "Baby",
            child: "Child",
            teenager: "Teenager",
            young_adult: "Young adult",
            adult: "Adult",
            adults_only: "Adults only",
            mature_adult: "Mature adult",
            senior_adult: "Senior adult",
          },
          exclude_nudity: "Safe (Exclude nudity)",
        },
      },
      purchase: {
        select_plan: "Select Plan",
        select_format: "Select Format & Size",
        manage_plans: "Manage Plans",
        format: "Select Format & Size",
        credit: "Credit",
        credits: "Credits",
        istock_photo_id: "IStock photo ID",
        istock_video_id: "IStock video ID",
        plan_exceeded: "Plan Exceeded",
        size: {
          small: "Small",
          medium: "Medium",
          large: "Large",
          x_large: "X Large",
          xx_large: "XX Large",
          vector: "Vector",
        },
        credits_remaining: "credits remainings",
        media_type_not_included:
          "Your current plan does not include this {{mediaType}}",
        images: "images",
        videos: "videos",
        days_left: "days left",
        credits_balance_too_small: "Your credits balance is too small",
        collection: "Collection",
        cost: "Cost",
      },
      buttons: {
        next: "Next",
        upload: "Upload",
        purchase: "Purchase",
      },
    },
    unsplash: {
      no_auth_title: "Access millions of images from Unsplash.",
      no_auth_action: "Connect to Unsplash",
      no_auth_statement:
        "Beautiful, free images and photos that you can download and use for any project. Better than any royalty free or stock photos.",
      select: "Select",
      popular_categories: "Popular categories",
      editorial: "Editorial",
      filters: {
        toggle_filters_button: "Filters",
        clear: "Clear",
        sort_options: {
          label: "Sort by",
          latest: "Latest",
          oldest: "Oldest",
          relevant: "Relevant",
          popular: "Popular",
        },
        orientation: {
          label: "Orientation",
          portrait: "Portrait",
          landscape: "Landscape",
          squarish: "Squarish",
        },
        categories: {
          label: "Categories",
        },
        license: {
          label: "License Type",
          creative: "Creative",
          editorial: "Editorial",
        },
        colors: {
          label: "Colors",
        },
        summary: {
          label: "Filters",
          color: "Color: {{color}}",
        },
        filters_in: "in",
        color_types: {
          tones: "Tones",
          black_and_white: "Black and white",
        },
      },
      summary: {
        description: "Description",
        permission: "Permission",
        free_to_use: "Free to use",
        published_at: "Published at",
        location: "Location",
        credit: "Credit",
        format: "Select Format & Size",
        size: {
          small: "Small",
          medium: "Medium",
          large: "Large",
          original_size: "Original size",
        },
      },
      colors: {
        orange: "Orange",
        red: "Red",
        yellow: "Yellow",
        green: "Green",
        teal: "Teal",
        blue: "Blue",
        purple: "Purple",
        magenta: "Magenta",
        white: "White",
        black: "Black",
        black_and_white: "Black and white",
      },
    },
    url: {
      inner_title: "Public URL of file to upload:",
      input_placeholder: "http://remote.site.example/images/remote-image.jpg",
    },
    metadataPage: {
      title: "Structured Metadata Values",
      subtitle: "1 asset selected",
      subtitle_plural: "{{count}} assets selected",
      tooltip:
        "Some required metadata fields are not set or the supplied values are invalid.",
      upload: "Upload",
      conflict_label: "Overwrite",
      intro: {
        openingText:
          "You can fill in the following fields to add new metadata to your assets on upload.",
        conflictWarning:
          "Note: Select ‘Overwrite’ for the fields where you want to apply the new values to both new and replaced assets.",
        closingText:
          "After upload, you can modify metadata for individual assets from the Media Library.",
      },
      closePrompt:
        "Are you sure you want to close this dialog box? Your files will not be uploaded.",
      backPrompt:
        "Are you sure? Your file selection and the metadata values you entered will be lost.",
      approveCancel: "Yes",
      cancel: "Cancel",
      validationErrors: {
        string: {
          min: "Must be at least {{min}} characters.",
          max: "Can't be more than {{max}} characters.",
          minAndMax: "Must be between {{min}}-{{max}} characters long.",
          regex: "Must include only XXXX.",
        },
        integer: {
          lessThan: "Must be less than {{less}}.",
          lessThanEqual: "Can't be more than {{max}}.",
          greaterThan: "Must be more than {{more}}.",
          greaterThanEqual: "Must be at least {{min}}.",
        },
        number: {
          lessThan: "Must be less than {{less}}.",
          lessThanEqual: "Can't be more than {{max}}.",
          greaterThan: "Must be more than {{more}}.",
          greaterThanEqual: "Must be at least {{min}}.",
        },
        enum: {
          oneOf:
            "{{originalValue}} needs to be one of the specified options above.",
        },
        set: {
          oneOf:
            "{{originalValue}} needs to be one of the specified options above.",
        },
        date: {
          lessThan: "Must be before {{max}}.",
          lessThanEqual: "Can’t be after {{max}}.",
          greaterThan: "Must be after {{min}}.",
          greaterThanEqual: "Can’t be before {{min}}.",
        },
        stringError: "Must be text.",
        numberError: "Must be a number.",
        integerError: "Must be a number.",
        dateError: "Must be a date.",
        enumError: "Choose one of the specified options.",
        setError: "Choose at least one of the specified options.",
        required: "This field is required.",
        integerTypeError: "This is an integer field.",
        digitsLimitError: "This number cannot be more than 16 digits long.",
        unsupportedFields:
          "Please contact your administrator, there is a problem with the following optional metadata fields: ",
        unsupportedRequiredFields:
          "Please contact your administrator, there is a problem with the following mandatory metadata fields: ",
      },
    },
  },
};

export default es;
