import React from "react";
// import { AdvancedImage } from "@cloudinary/react";
// import { Cloudinary } from "@cloudinary/url-gen";
// import { Resize } from "@cloudinary/url-gen/actions/resize";
import cloudinary from "services/CloudinaryService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const imgObj = {
  _id: "630fab9484e9479411af0594",
  notification_type: "upload",
  timestamp: "2022-08-31T18:38:40+00:00",
  request_id: "c8a3cdaeeec3a06e72db2f1475647800",
  asset_id: "f5369516809ee7d575470ac35789c3d4",
  public_id: "pruebas/vjos77usbv8sy5htnbfc",
  version: 1661971119,
  version_id: "146d0fd9e4acceeefc6a65a9c4fd5607",
  width: 5184,
  height: 3888,
  format: "jpg",
  resource_type: "image",
  created_at: "2022-08-31T18:38:39Z",
  tags: [],
  bytes: 8884308,
  type: "upload",
  etag: "24b89418d479538465488d16dd3316d1",
  placeholder: false,
  url: "http://res.cloudinary.com/albaysanti/image/upload/v1661971119/pruebas/vjos77usbv8sy5htnbfc.jpg",
  secure_url:
    "https://res.cloudinary.com/albaysanti/image/upload/v1661971119/pruebas/vjos77usbv8sy5htnbfc.jpg",
  folder: "pruebas",
  context: {
    custom: {
      autor: "santiggd@gmail.com",
    },
  },
  original_filename: "shutterstock_1663316989",
};

export default function Home() {
  // Create a Cloudinary instance and set your cloud name.
  // const cld = new Cloudinary({
  //   cloud: {
  //     cloudName: "albaysanti",
  //   },
  // });

  // cld.image returns a CloudinaryImage with the configuration set.
  // const myImage = cld.image("pruebas/qhcsfhz7qep1xnbufqx2");

  // myImage.resize(Resize.crop().aspectRatio(1.5));
  // // myImage.format("pdf");
  // const imgUrl = myImage.toURL();
  const imgId = "pruebas/qhcsfhz7qep1xnbufqx2";
  const imgUrl = cloudinary.getImgUrl(imgObj, {});
  const pdfUrl = cloudinary.getPdfUrl(imgObj, {});
  return (
    <div className="Home">
      <h1>Home</h1>
      <Box>
        <a href={pdfUrl}>{pdfUrl}</a>
      </Box>
      <Box sx={{ width: "100vw" }}>
        <Box component={"img"} src={imgUrl} sx={{ width: "50%" }} />
      </Box>
      <Box>
        <Button
          onClick={() => {
            // const pdfImage = cld.image("pruebas/qhcsfhz7qep1xnbufqx2");
            // pdfImage.resize(Resize.crop().aspectRatio(1.5));
            // pdfImage.format("pdf");
            // const pdfUrl = pdfImage.toURL();
            fetch("https://api.printnode.com/printjobs", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization:
                  "Basic " +
                  btoa(`${process.env.REACT_APP_PRINTNODE_API_KEY}:`),
              },
              body: JSON.stringify({
                printerId: 71612569,
                contentType: "pdf_uri",
                content: pdfUrl,
                options: {
                  fit_to_page: true,
                  copies: 1,
                  paper: `10x15cm 4"x6"`,
                },
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                console.log(data);
              });
          }}
          variant={"contained"}
        >
          Imprimir foto
        </Button>
      </Box>
    </div>
  );
}
