import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import Upload from "pages/Upload";
import Galery from "pages/Galery";
import Carrusel from "pages/Carrusel";
import Login from "pages/Login";
// import Grocerie from "pages/Grocerie";
import Authenticated from "pages/Authenticated";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Authenticated component={<Galery />} />} />
              <Route path="/login" element={<Login />}></Route>
              <Route
                path="/upload"
                element={<Authenticated component={<Upload />} />}
              ></Route>
              <Route
                path="/galeria"
                element={<Authenticated component={<Galery />} />}
              ></Route>
              <Route
                path="/carrusel"
                element={<Authenticated component={<Carrusel />} />}
              ></Route>
              <Route
                path="/home"
                element={<Authenticated component={<Home />} />}
              ></Route>
              {/* <Route path="/grocerie" element={<Grocerie />}></Route> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
