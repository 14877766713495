import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import selloImg from "./Sello.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const Login = () => {
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      window.location.href = "/";
    }
  }, []);

  const handleLogin = () => {
    if (password === "queguaposnosvemos") {
      localStorage.setItem("isAuthenticated", true);
      window.location.href = "/";
    }
  };

  const handleKeyDown = (event) => {
    console.log("User pressed: ", event.key);

    // console.log(message);

    if (event.key === "Enter") {
      // 👇️ your logic here
      handleLogin();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ textAlign: "center", marginTop: 3 }} maxWidth="sm">
        <Paper>
          <Box sx={{ maxWidth: 300, margin: "auto" }}>
            <img width="100%" src={selloImg} alt="Sello" />
          </Box>
          <Box sx={{ p: 2 }}>
            <TextField
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              label="Contraseña"
              fullWidth
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <Button onClick={handleLogin}>Entrar</Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
